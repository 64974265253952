import { Component, OnInit } from '@angular/core';
import { SupportwebwsService } from 'src/app/services/supportwebws.service';
import { ActivatedRoute } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { error } from '@angular/compiler/src/util';
import * as FileSaver from 'file-saver';
import { Storage } from '@ionic/storage';

declare var tinymce: any;
@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss'],
})
export class IncidentComponent implements OnInit {

  id: string;
  incident: any;
  supportrequests: any;
  docs: any;
  connections: any;
  userPrivileges: any;
  solutionTimeAccepted: boolean = false;
  publishingToLiveApproved: boolean = false;
  invoicingApproved: boolean = false;
  approvalLog: any;
  fullText: boolean = false;
  showFields1: boolean = true;
  showFields2: boolean = false;
  tinymceInit: any;

  // solutionTimeRejected: boolean = false;
  // publishingToLiveRejected: boolean = false;
  // invoicingRejected: boolean = false;

  waitingForCustomer: boolean = false;
  estimatedsolutiontime: string;
  acceptedsolutiontime: string;
  timespent: string;
  loading: boolean = false;
  selectedSegment: string;

  incidentPageCookies: any;
  lastClickedIncident: any;
  expand: boolean = false;

  constructor(private route: ActivatedRoute, private supportwebws: SupportwebwsService, private facadeService: FacadeService, private authService: AuthenticationService, private storage: Storage) {
    this.tinymceInit = {
      height: 500,
      menubar: false,
      selector: "textarea",
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount contextmenu'
      ],
      toolbar: 'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | image | help | fullscreen',
      contextmenu: " link image inserttable | cell row column deletetable",
      image_advtab: true,
      paste_data_images: true,
      force_br_newlines: true,
      force_p_newlines: false,
      forced_root_block: '', // Needed for 3.x
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        // Note: In modern browsers input[type="file"] is functional without 
        // even adding it to the DOM, but that might not be the case in some older
        // or quirky browsers like IE, so you might want to add it to the DOM
        // just in case, and visually hide it. And do not forget do remove it
        // once you do not need it anymore.

        input.onchange = function () {
          var file = input.files[0];

          var reader = new FileReader();
          reader.onload = function () {
            // Note: Now we need to register the blob in TinyMCEs image blob
            // registry. In the next release this part hopefully won't be
            // necessary, as we are looking to handle it internally.
            var id = 'blobid' + (new Date()).getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var base64 = (<any>reader.result).split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    }
  }

  ngOnInit() {
    // this.facadeService.menuIndexSelected = 1;

    this.storage.get("incidentpagecookies").then(x => {
      if(x) {
        this.incidentPageCookies = x;
        this.selectedSegment = x.selectedSegment;
        this.showFields1 = x.showFields1;
        this.showFields2 = x.showFields2;
        this.expand = x.expand;
      }
      else {
        this.selectedSegment = "supportRequestsTab";
        this.showFields1 = true;
        this.showFields2 = false;
        this.expand = false;
        this.incidentPageCookies = {
          selectedSegment: "supportRequestsTab",
          showFields1: true,
          showFields2: false,
          expand: false
        }
      }      
    });

    debugger;
    this.incident = {
      Attributes: {
        ebs_incidentno: "",
        ebs_description: "",
        ebs_waitingreasoncode: { Value: 0 },
        statuscode: { Value: 1 },
      },
      FormattedValues: {
        ebs_incidentpriorityid: "",
        ebs_solverid: "",
        ebs_estimatedsolutiontime: "",
        ebs_waitingreasoncode: ""
      }
    };

    this.userPrivileges = { CreateSupportRequest: false, ApproveSolutionTime: false, ApprovePublishingToLive: false, ApproveInvoicing: false };



    this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      this.supportwebws.getIncident(this.id).subscribe(result => {
        debugger;
        this.incident = result;
        this.solutionTimeAccepted = this.stringToBoolean(this.incident.FormattedValues.ebs_estimatedsolutiontimeaccepted);
        this.publishingToLiveApproved = this.stringToBoolean(this.incident.FormattedValues.ebs_approvedforlivepublishing);
        this.invoicingApproved = this.stringToBoolean(this.incident.FormattedValues.ebs_approvedforinvoicing);
        this.waitingForCustomer = this.stringToBoolean(this.incident.FormattedValues.ebs_waitingforcustomerresponse);
        this.estimatedsolutiontime = this.incident.Attributes.ebs_estimatedsolutiontime ? (this.incident.Attributes.ebs_estimatedsolutiontime / 60).toString() + " hod" : "";
        this.acceptedsolutiontime = this.incident.Attributes.ebs_acceptedsolutiontime ? (this.incident.Attributes.ebs_acceptedsolutiontime / 60).toString() + " hod" : "";
        this.timespent = this.calculateTimeSpent(this.incident);

        this.authService.getPrivileges().subscribe(p => {
          debugger;
          this.userPrivileges = p;

          if (p.ApproveSolutionTime == 866980000)
            this.userPrivileges.ApproveSolutionTime = false;
          else if (p.ApproveSolutionTime == 866980001 && p.ContactId != this.incident.Attributes.ebs_contactid.Id)
            this.userPrivileges.ApproveSolutionTime = false;
          else
            this.userPrivileges.ApproveSolutionTime = true;

          if (p.ApprovePublishingToLive == 866980000)
            this.userPrivileges.ApprovePublishingToLive = false;
          else if (p.ApprovePublishingToLive == 866980001 && p.ContactId != this.incident.Attributes.ebs_contactid.Id)
            this.userPrivileges.ApprovePublishingToLive = false;
          else
            this.userPrivileges.ApprovePublishingToLive = true;

          if (p.ApproveInvoicing == 866980000)
            this.userPrivileges.ApproveInvoicing = false;
          else if (p.ApproveInvoicing == 866980001 && p.ContactId != this.incident.Attributes.ebs_contactid.Id)
            this.userPrivileges.ApproveInvoicing = false;
          else
            this.userPrivileges.ApproveInvoicing = true;


        });
      });

      this.supportwebws.getDocsForIncident(this.id).subscribe(docs => {
        this.docs = docs;
      });

      this.supportwebws.getIncidentApprovalLog(this.id).subscribe(log => {
        this.approvalLog = log;
      });

      this.supportwebws.getConnectedIncidents(this.id).subscribe(cons => {
        debugger;
        this.connections = cons;
      });

    });

  }

  ionViewWillEnter() {
    this.supportwebws.getSupportRequestsToIncident(this.id).subscribe(result => {
      debugger;
      this.supportrequests = result;
    });
  }

  ngOnDestroy() {
    if(this.incidentPageCookies.expand && this.incidentPageCookies.selectedSegment == "supportRequestsTab") {
      this.supportrequests.forEach(element => {
        this.markAsRead(element);
      });
    }
  }

  itemTapped(event, supportrequest) {

    this.markAsRead(supportrequest);

    this.facadeService.routerParameters = { "incidentid": this.incident.Id }
    this.facadeService.router.navigate(["supportrequest/", supportrequest.Id]);
  }

  itemClicked($event, supportrequest) {
    if (this.lastClickedIncident != supportrequest.Id)
      this.lastClickedIncident = supportrequest.Id;
    else
      this.lastClickedIncident = null;

    this.markAsRead(supportrequest);

    event.stopPropagation();
  }

  markAsRead(supportrequest) {

    let index = this.facadeService.helperService.notifications.findIndex(x => x.Attributes.ebs_supportrequestid.Id == supportrequest.Id);

    if(index != -1)
    {
      debugger;
      this.facadeService.supportwebwsService.markAsRead(this.facadeService.helperService.notifications[index].Id).subscribe(x => {}, error => {});
      this.facadeService.helperService.notifications.splice(index, 1);
    }
  }

  expandAll() {
    this.expand = !this.expand;
    this.incidentPageCookies.expand = this.expand;
    this.storage.set("incidentpagecookies", this.incidentPageCookies);

    if(this.expand) {
      this.supportrequests.forEach(element => {
        this.markAsRead(element);
      });  
    }
  }

  showRow(incidentNo) {
    return incidentNo == this.lastClickedIncident || this.expand;
  }
  
  newSupportRequest() {
    debugger;
    this.facadeService.routerParameters = {
      "incident": this.incident
      // "incidentpriorityid": this.incident.Attributes.ebs_incidentpriorityid.Id,
      // "productcategoryid": this.incident.Attributes.ebs_productcategortyid.Id
    }
    this.facadeService.router.navigate(["supportrequest/", "newSupportRequest"])
  }

  rejectSupportRequest(rejectionText) {
    debugger;
    this.facadeService.routerParameters = {
      "incident": this.incident,
      "rejectionText": rejectionText
      // "incidentpriorityid": this.incident.Attributes.ebs_incidentpriorityid.Id,
      // "productcategoryid": this.incident.Attributes.ebs_productcategortyid.Id
    }
    this.facadeService.router.navigate(["supportrequest/", "newSupportRequest"])
  }

  approve(type) {
    var approvalText
    if (type == 866980000) {
      approvalText = "odhadovaný čas řešení";
    }
    else if (type == 866980001) {
      approvalText = "nasazení do živé DB";
    }
    else if (type == 866980002) {
      approvalText = "fakturaci";
    }

    this.facadeService.presentAlertConfirm("Odsouhlasit", `Opravdu si přejete odsouhlasit ${approvalText}?`,
      () => {
        this.loading = true;
        this.facadeService.supportwebwsService.approve(this.incident.Id, type, this.incident.Attributes.ebs_estimatedsolutiontime, true).subscribe((result) => {
          debugger;
          if (type == 866980000) {
            this.solutionTimeAccepted = result;
          }
          else if (type == 866980001) {
            this.publishingToLiveApproved = result;
          }
          else if (type == 866980002) {
            debugger;
            this.invoicingApproved = result;
          }

          if (result == true) {
            this.incident.FormattedValues.ebs_waitingforcustomerresponse = false;
            this.incident.FormattedValues.ebs_waitingreasoncode = null;
          }
          this.loading = false;

        }, error => {
          this.loading = false;
        });
      }, () => { this.loading = false });
  }

  reject(type) {
    let approvalText;
    let rejectionText;

    if (type == 866980000) {
      approvalText = "odhadovaný čas řešení";
      rejectionText = "- Zamítnutí odhadovaného času řešení ";
    }
    else if (type == 866980001) {
      approvalText = "nasazení do živé DB";
      rejectionText = "- Zamítnutí nasazení do živé DB ";
    }
    else if (type == 866980002) {
      approvalText = "fakturaci";
      rejectionText = "- Zamítnutí fakturace ";
    }

    this.facadeService.presentAlertConfirm("Zamítnout", `Opravdu si přejete zamítnout ${approvalText}?`,
      () => {
        this.loading = true;
        this.facadeService.supportwebwsService.approve(this.incident.Id, type, this.incident.Attributes.ebs_estimatedsolutiontime, false).subscribe((result) => {
          debugger;
          // if (type == 866980000) {
          //   this.solutionTimeAccepted = result;
          // }
          // else if (type == 866980001) {
          //   this.publishingToLiveApproved = result;
          // }
          // else if (type == 866980002) {
          //   debugger;
          //   this.invoicingApproved = result;
          // }

          if (result == true) {
            this.incident.FormattedValues.ebs_waitingforcustomerresponse = false;
            this.incident.FormattedValues.ebs_waitingreasoncode = null;
            this.incident.Attributes.ebs_waitingforcustomerresponse = false;
            this.incident.Attributes.ebs_waitingreasoncode = null;
          }
          this.loading = false;
          this.rejectSupportRequest(rejectionText);

        }, error => {
          this.loading = false;
        });
      }, () => { this.loading = false; });
  }

  calculateTimeSpent(i) {
    if (!i.Attributes.ebs_invoicingmethode)
      return "";

    if (i.Attributes.ebs_invoicingmethode.Value == 866980000) // 866980000 straveny cas ### 866,980,001 odsouhlaseny cas
      return i.Attributes.ebs_timespent ? +i.Attributes.ebs_timespent / 60 + " hod" : "";

    if (i.Attributes.ebs_invoicingmethode.Value == 866980001)
      return i.Attributes.ebs_acceptedsolutiontime ? +i.Attributes.ebs_acceptedsolutiontime / 60 + " hod" : "";
  }

  stringToBoolean(string) {
    if (!string)
      return false;

    switch (string.toLowerCase().trim()) {
      case "true": case "yes": case "ano": case "1": return true;
      case "false": case "no": case "ne": case "0": case null: return false;
      default: return Boolean(string);
    }
  }

  download(doc) {
    debugger;
    this.facadeService.supportwebwsService.downloadAttachment(doc.absoluteurl).subscribe(
      x => {
        FileSaver.saveAs(x.data, x.filename.replaceAll("\"", ""));
      },
      error => { this.facadeService.showMessage("Error", "", error.error.Message); }
    );
  }

  reload() {
    this.incidentPageCookies.selectedSegment = this.selectedSegment;
    this.storage.set("incidentpagecookies", this.incidentPageCookies);
  }

  showMore() {
    this.fullText = !this.fullText;
  }

  showFields1Fce() {
    this.showFields1 = !this.showFields1;
    this.incidentPageCookies.showFields1 = this.showFields1;
    this.storage.set("incidentpagecookies", this.incidentPageCookies);
  }

  showFields2Fce() {
    this.showFields2 = !this.showFields2;
    this.incidentPageCookies.showFields2 = this.showFields2;
    this.storage.set("incidentpagecookies", this.incidentPageCookies);
  }

  openIncident(id) {
    this.facadeService.router.navigate(["incident/", id]);
  }

  hasNotification(supportRequest) {
    
    if(!this.facadeService.helperService.notifications)
      return false;

    if(this.facadeService.helperService.notifications.find(x => x.Attributes.ebs_supportrequestid.Id == supportRequest.Id))
      return true;
    else
      return false;
  }
  

}
