import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { FacadeService } from './facade.service';
import { Storage } from '@ionic/storage';
import { promise } from 'protractor';

const TOKEN_KEY = 'auth-token';
const USR_PRIVILEGES = 'usr-privileges';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticationState = new BehaviorSubject(false);
  companyName: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(private storage: Storage, private plt: Platform, private router: Router, private facadeService: FacadeService) {
    debugger;
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }

  getToken(): Observable<any> {
    return from(this.storage.get(TOKEN_KEY));
  }

  getPrivileges(): Observable<any> {
    return from(this.storage.get(USR_PRIVILEGES));
  }

  public privileges: any;

  login(username: string, password: string): Observable<any> {
    return new Observable(observer => {
      //return this.storage.set(TOKEN_KEY, 'Bearer 1234567').then(() => {
      // this.storage.set(TOKEN_KEY, new LoggedUser(name, password)).then(() => {
      this.facadeService.supportwebwsService.login(username, password).subscribe((jwtToken) => {
        debugger;
        this.storage.set(TOKEN_KEY, jwtToken).then(() => {
          this.facadeService.supportwebwsService.getUserPrivileges().subscribe(privileges => {
            this.storage.set(USR_PRIVILEGES, privileges);
            this.companyName.next(privileges.CompanyName);
            this.privileges = privileges;
          });
        });
        this.authenticationState.next(true);
        observer.next(true);
        observer.complete();
      },
        err => {

          if (err.status === 401 || err.status === 404 || err.status === 500) {
            this.logout();
            observer.next(false);
            observer.complete();
          }
          console.log(err)
        })
    });
    // });
  }

  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
      this.storage.remove(USR_PRIVILEGES).then();
      this.privileges = null;
      //this.router.navigate(['login']);
    });
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable(observer => {
      this.getToken().subscribe(x => {
        if(x) {
          observer.next(true);
          observer.complete();
        }
        else {
          observer.next(false);
          observer.complete();
        }
      });

    });
  }


  isAuthenticated2() {
    return this.authenticationState.value;
  }
}
