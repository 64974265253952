import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';
import { FacadeService } from './facade.service';
import { SupportwebwsService } from './supportwebws.service';
import { AuthenticationService } from './authentication.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SupportwebwsInterceptor {

  // baseUrlTest: string = "https://localhost:44338";
  baseUrlLive: string = "https://supportwebws.azurewebsites.net";
  baseUrlTest: string = "https://supportwebwstest.azurewebsites.net";
  baseUrl: string = (<any>location).origin.includes("test") || (<any>location).origin.includes("localhost") ? this.baseUrlTest : this.baseUrlLive;

  constructor(private injector: Injector) {
    // this.baseUrl = this.baseUrlLive;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    debugger;
    var supportwebwsService = this.injector.get(SupportwebwsService);
    var facadeService = this.injector.get(FacadeService);
    var authenticationService = this.injector.get(AuthenticationService);

    return authenticationService.getToken().pipe(
      mergeMap(token => {
      let headers = req.headers.append('Authorization', 'Bearer ' + token);
      headers = headers.append("Access-Control-Allow-Origin", "*");
      
      var url = this.baseUrl + req.url;

      req = req.clone({
        headers, url
      });
  
      return next.handle(req);  
    }));
  }

}
