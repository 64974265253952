import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IncidentlistComponent } from './components/incidentlist/incidentlist.component';
import { IncidentComponent } from './components/incident/incident.component';
import { LoginComponent } from './components/login/login.component';
import { SupportrequestComponent } from './components/supportrequest/supportrequest.component';
import { SupportrequestlistComponent } from './components/supportrequestlist/supportrequestlist.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { AdminComponent } from './components/admin/admin.component';
import { RoleComponent } from './components/role/role.component';
import { UserComponent } from './components/user/user.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LicencecontractlistComponent } from './components/licencecontractlist/licencecontractlist.component';
import { LicencecontractComponent } from './components/licencecontract/licencecontract.component';
import { LicencerequestComponent } from './components/licencerequest/licencerequest.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  {path: '', component: LoginComponent, canActivate: [AuthGuardService]},
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  { path: 'login', component: LoginComponent},
  { path: 'incidentlist', component: IncidentlistComponent, canActivate: [AuthGuardService]},
  { path: 'incident/:id', component: IncidentComponent, canActivate: [AuthGuardService]},
  { path: 'supportrequestlist', component: SupportrequestlistComponent, canActivate: [AuthGuardService]},
  { path: 'supportrequest/:id', component: SupportrequestComponent, canActivate: [AuthGuardService]},
  { path: 'documentation', component: DocumentationComponent, canActivate: [AuthGuardService]},
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuardService]},
  { path: 'role/:id', component: RoleComponent, canActivate: [AuthGuardService]},
  { path: 'user/:id', component: UserComponent, canActivate: [AuthGuardService]},
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService]},
  { path: 'licencecontractlist', component: LicencecontractlistComponent, canActivate: [AuthGuardService]},
  { path: 'licencecontract/:id', component: LicencecontractComponent, canActivate: [AuthGuardService]},
  { path: 'licencerequest/:id', component: LicencerequestComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
       { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
