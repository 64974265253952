import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { FileBrowserTreeComponent } from '../file-browser-tree/file-browser-tree.component';
import { FileBrowser } from 'src/app/services/file-browser.service';
// import { FileBrowser } from 'ionic-json-file-browser';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent implements OnInit {

  loading: any = false;

  constructor(private facadeService: FacadeService, public fileBrowser: FileBrowser) { }

  ngOnInit() {
    this.facadeService.menuIndexSelected = 4;
    debugger;

    if(this.fileBrowser.entities && this.fileBrowser.entities.length > 0) {
      return;
    }
    else {
      this.loading = true;
    }

    this.facadeService.supportwebwsService.getDocumentation().subscribe(docs => {
      debugger;
      this.loading = false;
      this.fileBrowser.generateFileBrowser(docs.docTree, docs.docList, "0");
    }, error => {
      this.loading = false;
    });       
    
  }

}
