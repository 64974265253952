import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IncidentComponent } from './components/incident/incident.component';
import { IncidentlistComponent } from './components/incidentlist/incidentlist.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeCz from '@angular/common/locales/cs';
import { LoginComponent } from './components/login/login.component';
import { SupportwebwsInterceptor } from './services/supportwebws.interceptor';
import { IonicStorageModule } from '@ionic/storage';
import { AuthenticationService } from './services/authentication.service';
import { SupportrequestComponent } from './components/supportrequest/supportrequest.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SupportrequestlistComponent } from './components/supportrequestlist/supportrequestlist.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { FileBrowserTreeComponent } from './components/file-browser-tree/file-browser-tree.component';
import { FileBrowser } from './services/file-browser.service';
import { NgxsModule } from '@ngxs/store';
import { FileBrowserState } from './states/file-browser.state';
import { NodeSortingService } from './services/node-sorting.service';
import { FileBrowserIconComponent } from './components/file-browser-icon/file-browser-icon.component';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { AdminComponent } from './components/admin/admin.component';
import { UsersComponent } from './components/users/users.component';
import { RolesComponent } from './components/roles/roles.component';
import { UserComponent } from './components/user/user.component';
import { RoleComponent } from './components/role/role.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LicencecontractComponent } from './components/licencecontract/licencecontract.component';
import { LicencecontractlistComponent } from './components/licencecontractlist/licencecontractlist.component';
import { LicencerequestComponent } from './components/licencerequest/licencerequest.component';

registerLocaleData(localeCz, 'cs-CZ');

export function HttpLoaderFactory(http: HttpClient, back: HttpBackend) {
  http = new HttpClient(back);
  return new TranslateHttpLoader(http);
  //return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    IncidentComponent,
    IncidentlistComponent,
    TopbarComponent,
    LoginComponent,
    SupportrequestComponent,
    SupportrequestlistComponent,
    SettingsComponent,
    DocumentationComponent,
    FileBrowserTreeComponent,
    FileBrowserIconComponent,
    AdminComponent,
    UsersComponent,
    RolesComponent,
    UserComponent,
    RoleComponent,
    LicencecontractComponent,
    LicencecontractlistComponent,
    LicencerequestComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, HttpBackend]
      }
    }),
    EditorModule,
    NgxsModule.forRoot([FileBrowserState]),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: SupportwebwsInterceptor, multi: true },
    AuthenticationService,
    AuthGuardService,
    FileBrowser,
    NodeSortingService,
    FileChooser
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
