import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-supportrequestlist',
  templateUrl: './supportrequestlist.component.html',
  styleUrls: ['./supportrequestlist.component.scss'],
})
export class SupportrequestlistComponent implements OnInit {

  supportrequests: any;
  supportrequestsBackup: any;
  public searchControl: FormControl;
  searching: any = false;
  userPrivileges: any;
  desc: boolean = false;

  constructor(private router: Router, private facadeService: FacadeService, private authService: AuthenticationService) {
    this.searchControl = new FormControl();
  }

  ionViewWillEnter() {
    debugger;
    this.loadData();
  }

  ngOnInit() {

    this.facadeService.menuIndexSelected = 2;
    
    this.userPrivileges = { CreateSupportRequest: false };
    this.authService.getPrivileges().subscribe(p => {
      debugger;
      this.userPrivileges = p;
    });

    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe(search => {
        this.searching = false;
        this.searchItems(search);
      });
  }

  loadData() {
    this.searching = true;
    this.facadeService.supportwebwsService.getSupportRequestsToIncident(null).subscribe(result => {
      this.searching = false;
      this.supportrequests = result;
      this.supportrequestsBackup = result;
    },
    error => {
      debugger;
      this.searching = false;
      this.facadeService.showMessage("Error", "", error.error.Message);
    });
  }

  searchItemsApi(ev) {
    let serchText = ev;
    if (serchText) {
      this.facadeService.supportwebwsService.getSupportRequestsBySubject(serchText).subscribe((data) => {
        this.supportrequests = data;
      });
    }
    else {
      this.facadeService.supportwebwsService.getSupportRequestsToIncident(null).subscribe(result => {
        this.supportrequests = result;
      });
    }
  }

  searchItems(ev) {
    this.supportrequests = this.supportrequestsBackup;
    let serchText = ev.toLowerCase();
    if (serchText) {
      this.supportrequests = Array.from(this.supportrequests).filter(x => 
        (((<any>x).Attributes.subject ? (<any>x).Attributes.subject.toLowerCase() : "").includes(serchText) ||
        ((<any>x).FormattedValues.ebs_contactid ? (<any>x).FormattedValues.ebs_contactid.toLowerCase() : "").includes(serchText)) &&
        (<any>x).Attributes.regardingobjectid == null
        );
    }
    // else {
    //   this.facadeService.supportwebwsService.getSupportRequestsToIncident(null).subscribe(result => {
    //     this.supportrequests = result;
    //   });
    // }
  }

  itemTapped(event, supportrequest) {
    this.router.navigate(["supportrequest/", supportrequest.Id]);
  }

  newSupportRequest() {
    this.facadeService.routerParameters = null;
    this.facadeService.router.navigate(["supportrequest/", "newSupportRequest"])
  }

  onSearchInput() {
    this.searching = true;
  }

  sortByPriority() {
    debugger;
    if(this.desc) {
      this.supportrequests.sort((a, b) => {
        if(!a.FormattedValues.ebs_incidentpriorityid)
          return 1

        if(!b.FormattedValues.ebs_incidentpriorityid)
          return -1       
          
        if (a.FormattedValues.ebs_incidentpriorityid > b.FormattedValues.ebs_incidentpriorityid) return -1;
        else return 1;
      });  
    } else {
      this.supportrequests.sort((a, b) => {
        if(!a.FormattedValues.ebs_incidentpriorityid)
          return -1

        if(!b.FormattedValues.ebs_incidentpriorityid)
          return 1       

        if (a.FormattedValues.ebs_incidentpriorityid < b.FormattedValues.ebs_incidentpriorityid) return -1;
        else return 1;
      });
    }

    this.desc = !this.desc;
  }

  sortByCreatedOn() {
    if(this.desc) {
      this.supportrequests.sort((a, b) => {
        if (a.Attributes.createdon > b.Attributes.createdon) return -1;
        else return 1;
      });  
    } else {
      this.supportrequests.sort((a, b) => {
        if (a.Attributes.createdon < b.Attributes.createdon) return -1;
        else return 1;
      });
    }

    this.desc = !this.desc;
  }

}
