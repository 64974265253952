import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-licencecontractlist',
  templateUrl: './licencecontractlist.component.html',
  styleUrls: ['./licencecontractlist.component.scss'],
})
export class LicencecontractlistComponent implements OnInit {

  licenceContracts: any;
  searching: any = false;

  constructor(private router: Router, private facadeService: FacadeService) { }

  ngOnInit() {
    this.searching = true;
    this.facadeService.menuIndexSelected = 3;
    this.facadeService.supportwebwsService.getLicenceContracts().subscribe(result => {
      this.searching = false;
      this.licenceContracts = result;
    });
  }

  itemTapped(event, incident) {
    this.router.navigate(["licencecontract/", incident.Id]);
  }

}
