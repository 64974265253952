import { Injectable, Injector } from '@angular/core';
import { SupportwebwsService } from './supportwebws.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class FacadeService {

  private _supportwebws: SupportwebwsService;
  private _location: Location;
  private _translate: TranslateService;
  private _alertController: AlertController;
  private _router: Router;
  private _helperService: HelperService;

  constructor(private injector: Injector) { }

  //#region helperProperties
  public menuIndexSelected: number;
  //#endregion

  //#region navService
  public get supportwebwsService(): SupportwebwsService {
    if (!this._supportwebws) {
      this._supportwebws = this.injector.get(SupportwebwsService);
    }
    return this._supportwebws;
  }
  //#endregion

  //#region HelperService
  public get helperService(): HelperService {
    if (!this._helperService) {
      this._helperService = this.injector.get(HelperService);
    }
    return this._helperService;
  }
  //#endregion

  //#region Location
  public get location(): Location {
    if (!this._location) {
      this._location = this.injector.get(Location);
    }
    return this._location;
  }

  navigateBack() {
    this.location.back();
  }
  //#endregion

  //#region TranslateService
  public get translate(): TranslateService {
    if (!this._translate) {
      this._translate = this.injector.get(TranslateService);
    }
    return this._translate;
  }
  //#endregion

  //#region HelperFunctions
  public get alertController(): AlertController {
    if (!this._alertController) {
      this._alertController = this.injector.get(AlertController);
    }
    return this._alertController;
  }

  async showMessage(header: string, subHeader: string, message: string) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentAlertConfirm(header, message, fceOK, fceCancel = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      buttons: [
        {
          text: 'Zrušit',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            debugger;
            console.log('Confirm Cancel: ');
            if (fceCancel)
              fceCancel;
          }
        }, {
          text: 'Potvrdit',
          handler: fceOK
        }
      ]
    });

    await alert.present();
  }

  // async createLicenceRequest(line: any): Promise<any> {

  //   const tagReadButtons = [
  //     {
  //       text: 'Zrušit',
  //       role: 'cancel',
  //       cssClass: 'secondary',
  //       handler: () => {
  //         console.log('Confirm Cancel');
  //       }
  //     }, {
  //       text: 'Potvrdit',
  //       handler: (data) => {
  //         console.log('Confirm Ok');
  //         debugger;
  //         line.Attributes.ebs_newquantity = data.newquantity;
  //         this.supportwebwsService.createLicenceRequest(line).subscribe(x => {
  //           debugger;
  //         },
  //           error => {
  //             this.showMessage("Error", "", error.error.Message);
  //           });
  //       }
  //     }
  //   ];

  //   const alertCtrl = await this.alertController.create({
  //     header: 'Změna množství na licenci',
  //     subHeader: 'Platná od',
  //     inputs: [
  //       {
  //         name: 'datefrom',
  //         label: 'platné od',
  //         type: 'date',
  //         placeholder: 'zadejte datum platnosti',
  //       },
  //       {
  //         name: 'newquantity',
  //         label: 'množství',
  //         type: 'text',
  //         placeholder: 'zadejte nový počet licenci',
  //       }
  //     ],
  //     buttons: tagReadButtons
  //   });

  //   await alertCtrl.present();
  // }
  //#endregion

  //#region Router
  public get router(): Router {
    if (!this._router) {
      this._router = this.injector.get(Router);
    }
    return this._router;
  }

  private _routerParameters: JSON;
  public get routerParameters(): any {
    if (this._routerParameters) {
      return this._routerParameters;
    } else {
      return null;
    }
  }
  public set routerParameters(object: any) {
    this._routerParameters = object;
  }

  //#endregion

  //#region Excel
  exportexcel(data, filename): void {
    /* table id is passed over here */
    //let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filename);

    /* save to file */
    XLSX.writeFile(wb, filename + ".xlsx");

  }

  ////#endregion

  convertToPlain(rtf) {
    if (!rtf) return;
    // Create a new div element

    var tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = rtf;

    // Retrieve the text property of the element
    let plainText = tempDivElement.textContent || tempDivElement.innerText || "";
    const retval = this.removeXMLComment(plainText);
    return retval;
  }

  removeXMLComment(s) {
    const startComment = '<!--';
    const endComment = '-->';

    let start = s.indexOf(startComment);
    let end = s.indexOf(endComment);
    let retval = s;

    while (start > -1 && end > start) {
      retval = retval.substring(0, start) + retval.substring(end + endComment.length, retval.length);

      start = retval.indexOf(startComment);
      end = retval.indexOf(endComment);
    }
    return retval;
  }



}
