import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FacadeService } from 'src/app/services/facade.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  public username: string;
  public password: string;
  private jwtToken: string;

  passwordType: string = 'password';
  passwordIcon: string = 'eye';
  itemPath: string = "assets/essence.svg";
  
  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private router: Router, private facadeService: FacadeService) {
    this.createForm();
    // this.username = "";
    // this.password = "";
  }

  createForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

  }
  
  ngOnInit() {  
    debugger;
    this.facadeService.menuIndexSelected = 0;
  }

  login() {
    this.authenticationService.login(this.form.value.username, this.form.value.password).subscribe(logged => {
      debugger;
      if(logged) {
        this.router.navigate(['incidentlist']);
        this.form.setErrors({
          "auth": ""
        });
      }
      else {
        this.form.setErrors({
          // "auth": this.logginErrorTitle
          "auth": "Špatné přihlašovací údaje"
        });
      }
    });
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

}
