import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptions2 = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }),
  responseType: 'blob' as 'json',
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class SupportwebwsService {

  constructor(public http: HttpClient) {

  }

  login(username: string, password: string): Observable<any> {
    const url = "/api/login";

    var body = {
      "login": username,
      "password": password
    }

    return this.http.post<any>(url, body, httpOptions)
      .pipe(
        // map(this.extractData),
        tap(i => this.log(`loged in: ` + i)),
        //catchError(this.handleError('login', []))
      );
  }

  getLicenceContracts(): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/licencecontracts";

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched licencecontracts: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getLicenceContract(id: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/licencecontracts/" + id;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched licencecontract: ` + i)),
        catchError(this.handleError('getLicenceContract', []))
      );
  }

  getLicenceContractLines(licencecontractid: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/licencecontractlines?licencecontractid=" + licencecontractid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched licencecontractlines: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getLicenceContractLinesByParentLine(parentlicencecontractlineid: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/licencecontractlines?parentlicencecontractlineid=" + parentlicencecontractlineid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched licencecontractlines: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getLicenceContractLine(licencecontractlineid: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/licencecontractlines/" + licencecontractlineid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched licencecontractline: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getLicence(id: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/licence/" + id;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched licence: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getLicenceRequestsForLine(lineid: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/licencerequests?licencecontractlineid=" + lineid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched licencerequests: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getSellingPricesToLicenceContractLine(lineid: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/sellingprices?licencecontractlineid=" + lineid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched sellingprices: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  createLicenceRequest(line: any, newquantity: number, licencechangefrom: any, priceperone: number, totalprice: number): Observable<any> {
    debugger;
    const url = "/api/licencerequests/";
    var body = {
      requestname: `${newquantity - line.Attributes.ebs_quantity} x ${line.FormattedValues.ebs_licenceid}`,
      newquantity: newquantity,

      licencechangefrom: licencechangefrom,

      licencecontractid: line.Attributes.ebs_licencecontractid != null ? line.Attributes.ebs_licencecontractid.Id : null,
      licencecontractlineid: line.Attributes.ebs_licencecontractlineid != null ? line.Attributes.ebs_licencecontractlineid : null,

      priceperonelicence: priceperone,
      totalprice: totalprice
    };

    return this.http.post<any>(url, body, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`created licencerequest: ` + i)),
        //catchError(this.handleError('createSupportRequest', []))
      );
  }

  getIncidents(): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/incidents";

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched incidents: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getNotificationsForContact(contactid): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = `/api/notifications?contactid=${contactid}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched notifications: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  getIncidentsByState(statecode): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = `/api/incidents?statecode=${statecode}`;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched incidents: ` + i)),
        //catchError(this.handleError('getIncidents', []))
      );
  }

  searchIncidentsByIncidentNo(searchtext): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/incidents?incidentnolike=" + searchtext;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched incidents: ` + i)),
        catchError(this.handleError('getIncidents', []))
      );
  }

  searchIncidents(searchtext): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/incidents?searchtext=" + searchtext;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched incidents: ` + i)),
        catchError(this.handleError('getIncidents', []))
      );
  }

  getIncident(id: string): Observable<any> {
    //const url = "https://supportwebws.azurewebsites.net/api/incidents";
    const url = "/api/incidents/" + id;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched incident: ` + i)),
        catchError(this.handleError('getIncident', []))
      );
  }

  getSupportRequestsToIncident(incidentid: string): Observable<any> {
    const url = "/api/supportrequests?regardingobjectid=" + incidentid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched support requests: ` + i)),
        catchError(this.handleError('getSupportRequestsToIncident', []))
      );
  }

  getSupportRequestsBySubject(searText: string): Observable<any> {
    const url = "/api/supportrequests?subject=" + searText;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched support requests: ` + i)),
        catchError(this.handleError('getSupportRequestsToIncident', []))
      );
  }

  getSupportRequest(supportrequestid: string): Observable<any> {
    const url = "/api/supportrequests/" + supportrequestid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched supportrequestid: ` + i)),
        catchError(this.handleError('getSupportRequest', []))
      );
  }

  createSupportRequest(supportrequest: any): Observable<any> {
    const url = "/api/supportrequests/";
    debugger;
    var body = {
      subject: supportrequest.Attributes.subject,
      description: supportrequest.Attributes.description,
      regardingobjectid: supportrequest.Attributes.regardingobjectid,
      contractnoid: supportrequest.Attributes.ebs_contractnoid != null ? supportrequest.Attributes.ebs_contractnoid.Id : null,
      incidentpriorityid: supportrequest.Attributes.ebs_incidentpriorityid != null ? supportrequest.Attributes.ebs_incidentpriorityid.Id : null,
      productcategoryid: supportrequest.Attributes.ebs_productcategoryid != null ? supportrequest.Attributes.ebs_productcategoryid.Id : null
    };

    return this.http.post<any>(url, body, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`created supportrequest: ` + i)),
        //catchError(this.handleError('createSupportRequest', []))
      );
  }

  getDocsForIncident(incidentURL): Observable<any> {
    const url = "/api/docs/" + incidentURL;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        // map(this.extractData),
        tap(i => this.log(`fetched getDocsForIncident: ` + i)),
        catchError(this.handleError('getDocsForIncident', []))
      );
  }

  getDocsForSupportRequest(supportrequestID): Observable<any> {
    const url = "/api/docs?supportrequestid=" + supportrequestID;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        // map(this.extractData),
        tap(i => this.log(`fetched getDocsForIncident: ` + i)),
        catchError(this.handleError('getDocsForIncident', []))
      );
  }

  getUserPrivileges(): Observable<any> {
    const url = "/api/privileges";

    return this.http.get<any>(url, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`fetched UserPrivileges: ` + i)),
        catchError(this.handleError('getUserPrivileges', []))
      );
  }

  getProductCategories(): Observable<any> {
    const url = "/api/productcategories";

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched UserPrivileges: ` + i)),
        catchError(this.handleError('getUserPrivileges', []))
      );
  }

  getIncidentPriorities(productcategoryid, parentproductcategoryid): Observable<any> {
    const url = "/api/incidentpriorities?productcategoryid=" + productcategoryid + "&parentproductcategoryid=" + parentproductcategoryid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched getIncidentPriorities: ` + i)),
        catchError(this.handleError('getIncidentPriorities', []))
      );
  }

  getIncidentPrioritiesByContract(contractid): Observable<any> {
    const url = "/api/incidentpriorities?contractid=" + contractid;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched getIncidentPrioritiesByContract: ` + i)),
        catchError(this.handleError('getIncidentPrioritiesByContract', []))
      );
  }

  resetPassword(pwd): Observable<any> {
    const url = "/api/resetpassword";

    var body = {
      "password": pwd
    };

    return this.http.post<any>(url, body, httpOptions)
      .pipe(
        // map(this.extractData),
        tap(i => this.log(`loged in: ` + i)),
        //catchError(this.handleError('login', []))
      );
  }

  downloadAttachment(sharepointUrl): Observable<any> {
    const url = "/api/docs?fileurl=" + sharepointUrl;

    return this.http.get<Response>(url, httpOptions2)
      .pipe(
        map(this.extractFile),
        tap(i => this.log(`downloadAttachment: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getFileAnonymousLink(sharepointUrl): Observable<any> {
    const url = "/api/docs?fileurlforanonymlink=" + sharepointUrl;

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        tap(i => this.log(`getFileAnonymousLink: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  uploadAttachment(fileRelativePath, data): Observable<any> {
    const url = "/api/uploaddoc";

    var body = {
      "fileRelativePath": fileRelativePath,
      "data": data
    }

    return this.http.post<Response>(url, body, httpOptions)
      .pipe(
        tap(i => this.log(`uploadAttachment: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  uploadAttachments(files): Observable<any> {
    const url = "/api/uploaddocs";

    return this.http.post<Response>(url, files, httpOptions)
      .pipe(
        tap(i => this.log(`uploadAttachment: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getDocumentation(): Observable<any> {
    const url = "/api/docs?";

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        //map(this.extractFile),
        tap(i => this.log(`getDocumentation: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getUser(id): Observable<any> {
    const url = "/api/contacts/" + id;

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`getUser: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getUsers(): Observable<any> {
    const url = "/api/contacts";

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`getUsers: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getRole(id): Observable<any> {
    const url = "/api/roles/" + id;

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`getRoles: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getRoles(): Observable<any> {
    const url = "/api/roles";

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`getRoles: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  createRole(role): Observable<any> {
    const url = "/api/roles";

    var contactrole = {
      name: role.Attributes.ebs_name,
      incidentvisibility: role.Attributes.ebs_incidentvisibilitycode.Value,
      createsupportrequest: role.Attributes.ebs_createsupportrequest,
      approvesolutiontime: role.Attributes.ebs_approvesolutiontime,
      approvelivepublishing: role.Attributes.ebs_approvepublishingtolive,
      approveinvoicing: role.Attributes.ebs_approveinvoicing,
      administration: role.Attributes.ebs_administration,
      readlicences: role.Attributes.ebs_readlicences,
      createlicencerequest: role.Attributes.ebs_createlicencerequest
    }
    debugger;

    return this.http.post<Response>(url, contactrole, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`createRole: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  updateRole(id, role): Observable<any> {
    const url = "/api/roles/" + id;

    var contactrole = {
      name: role.Attributes.ebs_name,
      incidentvisibility: role.Attributes.ebs_incidentvisibilitycode.Value,
      createsupportrequest: role.Attributes.ebs_createsupportrequest,
      approvesolutiontime: role.Attributes.ebs_approvesolutiontime,
      approvelivepublishing: role.Attributes.ebs_approvepublishingtolive,
      approveinvoicing: role.Attributes.ebs_approveinvoicing,
      administration: role.Attributes.ebs_administration,
      readlicences: role.Attributes.ebs_readlicences,
      createlicencerequest: role.Attributes.ebs_createlicencerequest
    }
    debugger;

    return this.http.put<Response>(url, contactrole, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`updateRole: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  deleteRole(id): Observable<any> {
    const url = "/api/roles/" + id;

    return this.http.delete<Response>(url, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`deleteRole: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getRoleBindings(userid): Observable<any> {
    const url = "/api/rolebindings?userid=" + userid;

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`getRoleBindings: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  createRoleBindings(rb): Observable<any> {
    const url = "/api/rolebindings";

    return this.http.post<Response>(url, rb, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`createRoleBinding: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  deleteRoleBindings(ids): Observable<any> {
    const url = "/api/rolebindingsdelete";

    return this.http.post<Response>(url, ids, httpOptions)
      .pipe(
        //map(this.extractData),
        tap(i => this.log(`deleteRoleBinding: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  getContracts(): Observable<any> {
    const url = "/api/contracts";

    return this.http.get<Response>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`getContracts: ` + i)),
        //catchError(this.handleError('downloadAttachment', []))
      );
  }

  approve(id, approvalType, solutionTime, approveOrReject: boolean): Observable<any> {
    const url = "/api/incidents";

    var body = {
      "id": id,
      "type": approvalType,
      "approvedtime": approvalType == 866980000 ? (solutionTime ? solutionTime : null) : null,
      "approveOrReject": approveOrReject
    }

    return this.http.post<any>(url, body, httpOptions)
      .pipe(
        // map(this.extractData),
        tap(i => this.log(`loged in: ` + i)),
        //catchError(this.handleError('login', []))
      );
  }

  markAsRead(id): Observable<any> {
    const url = "/api/notifications/" + id;
   
    return this.http.put<any>(url, null, httpOptions)
      .pipe(
        // map(this.extractData),
        tap(i => this.log(`loged in: ` + i)),
        //catchError(this.handleError('login', []))
      );
  }


  getIncidentApprovalLog(incidentNo: string): Observable<any> {

    const url = "/api/incidentapprovallog?incidentno=" + incidentNo;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched incidents: ` + i)),
        catchError(this.handleError('getIncidents', []))
      );

  }

  getConnectedIncidents(record1id: string): Observable<any> {

    const url = "/api/incidents?record1id=" + record1id;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        map(this.extractData),
        tap(i => this.log(`fetched incidents: ` + i)),
        catchError(this.handleError('getIncidents', []))
      );

  }

  private extractData(res: any) {
    let body = res;
    debugger;

    if (!body)
      return;

    if (Array.isArray(body)) {
      body.forEach(element => {
        let fv = element.FormattedValues.reduce(function (acc, curr) {
          acc[curr.Key] = curr.Value;
          return acc;
        }, {})

        let att = element.Attributes.reduce(function (acc, curr) {
          acc[curr.Key] = curr.Value;
          return acc;
        }, {})

        element.FormattedValues = fv;
        element.Attributes = att;
      });
    }
    else {
      let fv = body.FormattedValues.reduce(function (acc, curr) {
        acc[curr.Key] = curr.Value;
        return acc;
      }, {})

      let att = body.Attributes.reduce(function (acc, curr) {
        acc[curr.Key] = curr.Value;
        return acc;
      }, {})

      body.FormattedValues = fv;
      body.Attributes = att;
    }

    return body;
  }

  private extractFile(res: Response) {
    //response
    debugger;
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename=([^;]+)/ig.exec(contentDisposition);
    const fileName = (matches[1] || 'untitled').trim();

    return {
      data: res.body,
      filename: fileName
    }
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      //this.showMessage("", "", error.statusText)

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a JobService message with the MessageService */
  private log(message: string) {
    //this.messageService.add('JobService: ' + message);
    console.info(message)
  }
}
