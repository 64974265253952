import { Component, OnInit } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FacadeService } from './services/facade.service';
import { AuthenticationService } from './services/authentication.service';
import { ConfigurationService } from './services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  supportMail = (<any>location).origin.includes("test") || (<any>location).origin.includes("localhost") ? "crm_demo@essencebs.onmicrosoft.com" : "support@essencebs.com";
  itemPath: string = "assets/essence.svg";

  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Menu.Login',
      url: 'login',
      icon: 'log-in',
      unauthvisible: true
    },
    {
      title: 'Menu.Incidents',
      url: 'incidentlist',
      icon: 'list',
      unauthvisible: false
    },
    {
      title: 'Menu.SupportRequests',
      url: 'supportrequestlist',
      icon: 'list',
      unauthvisible: false
    },
    {
      title: 'Menu.LicenceContracts',
      url: 'licencecontractlist',
      icon: 'documents-outline',
      unauthvisible: false
    },
    {
      title: 'Menu.Documentation',
      url: 'documentation',
      icon: 'book',
      unauthvisible: false
    },
    {
      title: 'Menu.Admin',
      url: 'admin',
      icon: 'people',
      unauthvisible: false
    },
    {
      title: 'Menu.Settings',
      url: 'settings',
      icon: 'settings',
      unauthvisible: false
    },
    {
      title: 'Menu.Logout',
      url: '/login',
      icon: 'log-out',
      click: 'logout',
      unauthvisible: false
    },
    // {
    //   title: 'Inbox',
    //   url: '/folder/Inbox',
    //   icon: 'mail'
    // },
    // {
    //   title: 'Outbox',
    //   url: '/folder/Outbox',
    //   icon: 'paper-plane'
    // },
    // {
    //   title: 'Favorites',
    //   url: '/folder/Favorites',
    //   icon: 'heart'
    // },
    // {
    //   title: 'Archived',
    //   url: '/folder/Archived',
    //   icon: 'archive'
    // },
    // {
    //   title: 'Trash',
    //   url: '/folder/Trash',
    //   icon: 'trash'
    // },
    // {
    //   title: 'Spam',
    //   url: '/folder/Spam',
    //   icon: 'warning'
    // }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public facadeService: FacadeService,
    public authenticationService: AuthenticationService,
    private _config: ConfigurationService,
    private menuCtrl: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // let userLanguage = window.navigator.language ? window.navigator.language : "cs";
      // userLanguage = userLanguage == "cs" || userLanguage == "sk" ? "cs" : "en";
      // this.facadeService.translate.setDefaultLang(userLanguage);

      this.facadeService.translate.setDefaultLang(this._config.getLanguage(this._config.config.language));
    });
  }

  ngOnInit() {
    //const path = window.location.pathname.split('folder/')[1];
    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      this.facadeService.menuIndexSelected = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
    }
    this.authenticationService.getPrivileges().subscribe(p => {
      this.authenticationService.privileges = p;

      this.facadeService.supportwebwsService.getNotificationsForContact(p.ContactId).subscribe(result => {
      
        this.facadeService.helperService.notifications = result;

  
      });

    });
  }

  setVisibility(p) {
    var visible = p.unauthvisible ? !this.authenticationService.isAuthenticated2() : this.authenticationService.isAuthenticated2();

    if (p.title == "Menu.Admin")
      visible = visible && this.authenticationService.privileges != null ?
        this.authenticationService.privileges.Administration : false;

    if (p.title == "Menu.LicenceContracts")
      visible = visible && this.authenticationService.privileges != null ?
        this.authenticationService.privileges.ReadLicences : false;

    return visible;
  }

  callFunction(func, i) {
    debugger;
    this.facadeService.menuIndexSelected = i;
    // const path = window.location.pathname.split('/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
    // }
    if (func == "logout") {
      this.facadeService.menuIndexSelected = 0;
      this.authenticationService.logout();
    }
  }

  
}
