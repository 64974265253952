import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  users: any;

  constructor(private router: Router, private facadeService: FacadeService) { }

  ngOnInit() {
    this.facadeService.supportwebwsService.getUsers().subscribe(users => {
      debugger;
      this.users = users;
    });
  }

  click(user) {
    this.router.navigate(["user/", user.Id]);
  }

}
