import { Injectable } from '@angular/core';
import { Configuration } from '../models/config';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public config: Configuration = { 'language': '' }

  constructor(private platform: Platform) {
    this.load()
  }

  load() {
    // window.localStorage.removeItem('config');
    if (window.localStorage.getItem('config') !== null) {
      this.config = JSON.parse(window.localStorage.getItem('config'));
    }
    else {
      let userLanguage = "browserDefault";

      this.config = {
        'language': userLanguage
      };
    }
  }

  save(config: Configuration) {
    window.localStorage.setItem('config', JSON.stringify(this.config));
  }

  getLanguage(code: string) {
    let userLanguage;
    if(code == "browserDefault") {
      userLanguage = window.navigator.language ? window.navigator.language : "cs";
    }
    else {
      userLanguage = code;
    }

    userLanguage = userLanguage == "cs" || userLanguage == "sk" ? "cs" : "en";
    return userLanguage;
  }
}
