import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { ActivatedRoute, ChildrenOutletContexts } from '@angular/router';
import { existsSync } from 'fs';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import * as FileSaver from 'file-saver';
import { LoadingController, IonInput } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var tinymce: any;
@Component({
  selector: 'app-supportrequest',
  templateUrl: './supportrequest.component.html',
  styleUrls: ['./supportrequest.component.scss'],
})
export class SupportrequestComponent implements OnInit {

  sharepointLive = "/sites/CRM_Essence/ebs_supportrequest";
  sharepointDev = "/sites/supportwebtest/ebs_supportrequest";
  sharepointTest = "/sites/CRM_sandbox/ebs_supportrequest";

  id: string;
  supportrequest: any;
  pruductCategories: any;
  incidetnPriorities: any = [];
  contracts: any = [];
  isNew: boolean;
  incident: any;
  incidentid: string = null;
  incidentpriority: string = null;
  productcategory: string = null;
  incidentpriorityid: any = null;
  productcategoryid: any = null;
  docs: any;
  loading: any;
  isLoading: boolean;
  attachmentstring: string = "Nevybrán žádný soubor";
  noFileChoosenLabel: string = "";

  attachmentFiles: File[];
  imageFiles: File[] = [];
  fileCounter: number = 0;

  private form: FormGroup;

  tinymceInit: any;

  constructor(private route: ActivatedRoute, private facadeService: FacadeService,
    private fileChooser: FileChooser, public loadingController: LoadingController,
    private changeRef: ChangeDetectorRef, private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({
      subject: ['', Validators.required]
    });

    this.tinymceInit = {
      height: 500,
      menubar: false,
      selector: "textarea",
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount contextmenu'
      ],
      toolbar: 'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | image | help | fullscreen',
      contextmenu: " link image inserttable | cell row column deletetable",
      image_advtab: true,
      paste_data_images: true,
      force_br_newlines: true,
      force_p_newlines: false,
      forced_root_block: '', // Needed for 3.x
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        // Note: In modern browsers input[type="file"] is functional without 
        // even adding it to the DOM, but that might not be the case in some older
        // or quirky browsers like IE, so you might want to add it to the DOM
        // just in case, and visually hide it. And do not forget do remove it
        // once you do not need it anymore.

        input.onchange = function () {
          var file = input.files[0];

          var reader = new FileReader();
          reader.onload = function () {
            // Note: Now we need to register the blob in TinyMCEs image blob
            // registry. In the next release this part hopefully won't be
            // necessary, as we are looking to handle it internally.
            var id = 'blobid' + (new Date()).getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var base64 = (<any>reader.result).split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    }

  }

  ngOnInit() {
    // this.facadeService.menuIndexSelected = 2;

    if (this.facadeService.routerParameters && this.facadeService.routerParameters.incident) {
      debugger;
      this.incident = this.facadeService.routerParameters.incident;
      this.incidentid = this.facadeService.routerParameters.incident.Id;
      this.incidentpriority = this.incident.FormattedValues.ebs_incidentpriorityid;
      this.productcategory = this.incident.FormattedValues.ebs_productcategortyid;
      this.incidentpriorityid = this.incident.Attributes.ebs_productcategortyid;
      this.productcategoryid = this.incident.Attributes.ebs_incidentpriorityid;
    }

    let rejectionText = this.facadeService.routerParameters ? this.facadeService.routerParameters.rejectionText ? this.facadeService.routerParameters.rejectionText : "" : "";

    debugger;
    this.supportrequest = {
      Attributes: {
        subject: this.incident ? ` ${this.incident.Attributes.ebs_incidentno}: ${this.incident.Attributes.ebs_description} ${rejectionText}` : "",
        description: "",
        regardingobjectid: this.incidentid,
        ebs_productcategoryid: this.incidentpriorityid,
        ebs_incidentpriorityid: this.productcategoryid
      },
      FormattedValues: {
        ebs_productcategoryid: this.productcategory,
        ebs_incidentpriorityid: this.incidentpriority
      }
    };

    this.facadeService.supportwebwsService.getProductCategories().subscribe(categories => {
      debugger;
      var root = categories.filter(x => !x.FormattedValues.parentsubject)[0];
      this.pruductCategories = categories.filter(x => x.Attributes.parentsubject && x.Attributes.parentsubject.Id == root.Id);
    });

    this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      if (this.id != "newSupportRequest") {
        this.isNew = false;
        this.facadeService.supportwebwsService.getSupportRequest(this.id).subscribe(result => {
          debugger;
          this.supportrequest = result;

          //this.supportrequest.Attributes.description += "<img src='https://blog.inpage.cz/obrazek/3/kitten-jpg/' alt='Girl in a jacket' width='500' height='600'>"
          //this.supportrequest.Attributes.description += "<img src='https://supportwebws.azurewebsites.net/api/docs?fileurl=https://essencebs.sharepoint.com/sites/CRM_Essence/ebs_supportrequest/Nejde zaúčtovat banku v SSCIV a SM_3D13F15D8155EB11BB23000D3AB8D6B8/email/Nejde zaúčtovat banku v SSCIV a SM_FED2F8578155EB11BB23000D3AB8D6B8/image001.png' alt='Girl in a jacket' width='500' height='600'>"

          this.form.controls["subject"].setValue(this.supportrequest.Attributes.subject);
        });

        this.facadeService.supportwebwsService.getDocsForSupportRequest(this.id).subscribe(docs => {
          this.docs = docs;
        })

      }
      else {
        this.isNew = true;
      }
    });

    
    this.facadeService.translate.get('SupportRequest.NoFileChoosen').subscribe((res: string) => {
      this.noFileChoosenLabel = res;
      this.attachmentstring = this.noFileChoosenLabel;
    });
  }

  @ViewChild('subject') inputElement: IonInput;

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputElement.setFocus();
    }, 400);
  }

  async loadingPresent() {
    this.isLoading = true;
    return await this.loadingController.create({
      message: 'Zakládám požadavek...',
      spinner: 'crescent',
      cssClass: 'custom-class custom-loading'
    }).then(a => {
      a.present().then(() => {
        console.log('loading presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort laoding'));
        }
      });
    });
  }

  async loadingDismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('loading dismissed'));
  }

  // product selected -> get contracts
  getContracts($event) {

    this.contracts = null;
    this.incidetnPriorities = null;
    this.supportrequest.Attributes.ebs_contractnoid = null;
    this.supportrequest.Attributes.ebs_incidentpriorityid = null;

    this.facadeService.supportwebwsService.getContracts().subscribe(contracts => {
      debugger;
      var pc = this.pruductCategories.filter(x => x.Attributes.title.trim() == $event.detail.value.trim());
      if (pc) {
        // save product to support request
        this.supportrequest.Attributes.ebs_productcategoryid = { Id: pc[0].Id };
        contracts = contracts.filter(c => c.Attributes.ebs_productcategoryid.Id == pc[0].Attributes.subjectid ||
          c.Attributes.ebs_productcategoryid.Id == pc[0].Attributes.parentsubject.Id);

        this.contracts = contracts;
        if (this.contracts.length == 1) {
          this.supportrequest.Attributes.ebs_contractnoid = this.contracts[0];
          // var event = {
          //   detail: { value : this.contracts[0]}
          // };
          //this.getPriorities(event);
          this.changeRef.detectChanges();
        }
      }

    });
  }

  // contract selected -> get priorities
  getPriorities($event) {

    debugger;
    if ($event.detail.value == "")
      return;

    this.incidetnPriorities = null;
    this.supportrequest.Attributes.ebs_incidentpriorityid = null;

    this.supportrequest.Attributes.ebs_contractnoid = $event.detail.value;
    this.facadeService.supportwebwsService.getIncidentPrioritiesByContract($event.detail.value.Id).subscribe(ip => {
      this.incidetnPriorities = ip;
      this.changeRef.detectChanges();
    });
  }

  selectPriority($event) {
    //this.supportrequest.Attributes.ebs_incidentpriorityid = { Id: $event.detail.value.Id };
    this.supportrequest.Attributes.ebs_incidentpriorityid = $event.detail.value;
  }

  checkRequired() {
    this.supportrequest.Attributes.subject = this.form.value.subject;
    if (this.supportrequest.Attributes.subject == "") {
      this.form.setErrors({
        // "auth": this.logginErrorTitle
        "required": "Předmět je povinné pole"
      });
    }
    else {
      this.form.setErrors({
        // "auth": this.logginErrorTitle
        "required": ""
      });
    }
  }

  send() {
    if (this.supportrequest.Attributes.subject == "") {
      this.facadeService.showMessage("Chyba", "", "Vyplňte prosím všechna povinná pole.");
      return;
    }

    if (this.supportrequest.Attributes.description.length > 1048576) {
      this.facadeService.showMessage("Info", "", "Obrázky v textu jsou příliš veliké. Snižte prosím jejich kvalitu nebo je přiložte jako přílohu. Nestačí pouze zmenšit jejich rozměr v tomto textovém editoru, protože tím zůstane jejich kvalita a tímpádem i velikost zachována.");
      return
    }

    ///////////////////////////
    //alert(this.supportrequest.Attributes.description)

    let images = this.findImagesInDescription(this.supportrequest.Attributes.description);
    debugger;
    let i = 1;
    images.forEach((img) => {
      fetch(img.src)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], `img_${i++}.${img.extension}`, { type: img.type });
          this.imageFiles.push(file);
        })
    });

    ///////////////////////////

    this.loadingPresent();
    this.facadeService.supportwebwsService.createSupportRequest(this.supportrequest).subscribe(guid => {
      if (guid) {
        debugger;
        if (this.attachmentFiles && this.attachmentFiles.length > 0) {
          this.uploadAttachmentsToSHP(guid, this.attachmentFiles);
        }
        if (this.imageFiles && this.imageFiles.length > 0) {
          this.uploadAttachmentsToSHP(guid, this.imageFiles);
        }
        else {
          if (this.incidentid)
            this.facadeService.router.navigate(["incident/", this.incidentid]);
          else
            this.facadeService.router.navigate(["supportrequestlist/"]);

          this.loadingDismiss();
        }

      }
    },
      error => {
        this.loadingDismiss();
        debugger;
        this.facadeService.showMessage("Chyba", "", error.error.Message);
      });
  }

  findImagesInDescription(content) {
    const imgRegex = /<img.*?src=['"](.*?)['"]/gi;
    const imgTags = content.match(imgRegex);

    const images = [];
    if (imgTags) {
      imgTags.forEach((imgTag) => {
        const srcRegex = /src=['"](data:(image\/(.*?));.*?)['"]/i;
        const src = imgTag.match(srcRegex);
        if (src) {
          images.push({
            src: src[1],
            type: src[2],
            extension: src[3]
          });
        }
      });
    }
    return images;
  }

  uploadAttachmentsToSHP(guid, attachmentFiles) {
    var url = "";
    var data = null;

    guid = guid.replaceAll("-", "");

    url = (<any>location).origin.includes("test") || (<any>location).origin.includes("localhost") ? this.sharepointTest : this.sharepointLive;

    url = url + "/" + this.nameChars(this.supportrequest.Attributes.subject) + "_" + guid + "/";
    this.readAllFiles(attachmentFiles, url);
  }

  readAllFiles(file_list, url) {
    let promises = [];
    for (let file of file_list) {
      let filePromise = new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var json = {
            "fileRelativePath": url + file.name,
            "data": (<string>reader.result).split(',')[1]
          }
          resolve(json)
        };
      });
      promises.push(filePromise);
    }

    Promise.all(promises).then(fileContents => {
      debugger;
      this.facadeService.supportwebwsService.uploadAttachments(fileContents).subscribe(result => {
        if (this.incidentid)
          this.facadeService.router.navigate(["incident/", this.incidentid]);
        else
          this.facadeService.router.navigate(["supportrequestlist/"]);

        this.loadingDismiss();
      },
        error => {
          this.facadeService.showMessage("Error", "", error.error.Message);
          this.loadingDismiss();
        });
    });
  }

  nameChars(name: string) {
    return name.replace(/[.*:&|?"\\\/]/gi, "-");
  }

  openFileChooser() {
    this.fileChooser.open()
      .then(uri => console.log(uri))
      .catch(e => console.log(e));
  }

  change(event: any) {
    debugger;
    console.log(event.target.files);
    this.attachmentFiles = event.target.files;

    var att = Array.from(this.attachmentFiles);

    this.attachmentstring = att.map(e => (<any>e).name).join(", ");
    if (!this.attachmentstring)
      // this.attachmentstring = "Nevybrán žádný soubor";
      this.attachmentstring = this.noFileChoosenLabel;
  }

  download(doc) {
    debugger;
    this.facadeService.supportwebwsService.downloadAttachment(doc.absoluteurl).subscribe(
      x => {
        FileSaver.saveAs(x.data, x.filename.replaceAll("\"", ""));
      },
      error => { this.facadeService.showMessage("Error", "", error.error.Message); }
    );
  }

  getFile() {
    (<any>document.getElementById("upload").children[0]).click();
  }

}
