import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

  @Input() title: string;

  constructor(private facadeService: FacadeService, private authService: AuthenticationService) { }

  ngOnInit() {

    if(!this.authService.companyName.value)
    this.authService.getPrivileges().subscribe(priv => {
      this.authService.companyName.next(priv.CompanyName);
    });
    
  }

  navigateBack() {
    this.facadeService.navigateBack();
  }

}
