/* ==================================================================================================== */
/* File Browser Tree Component																			*/
/* ==================================================================================================== */

/* Module imports */
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
/* State imports */
import { Store, Select } from "@ngxs/store";
import { FileBrowserState } from "../../states/file-browser.state";
import { NodeEntity } from "../../states/file-browser.model";
import {
	ToggleFolder,
	GetNode,
	UnselectNode,
	Open
} from "../../states/file-browser.actions";
/* Component imports */
// import { FileBrowserActionSheetComponent } from "../file-browser-action-sheet/file-browser-action-sheet.component";
/* Service imports */
import { FileBrowser } from "../../services/file-browser.service";
import { FacadeService } from 'src/app/services/facade.service';
import * as FileSaver from 'file-saver';

@Component({
	selector: "file-browser-tree",
	templateUrl: "file-browser-tree.component.html",
	styleUrls: ["file-browser-tree.component.scss"],
	// providers: [FileBrowserActionSheetComponent]
})
export class FileBrowserTreeComponent implements OnInit {
	@Select(FileBrowserState.getNodeEntity) nodes$: Observable<NodeEntity>;
	public _downloading = false;
	public _generatingLink = false;

	public _lastNode = null;

	constructor(
		public store: Store,
		public fileBrowser: FileBrowser,
		private facadeService: FacadeService
		// public fileBrowserActionSheet: FileBrowserActionSheetComponent
	) { }

	ngOnInit() { }

	toggleFolder(node) {
		debugger;
		event.stopPropagation();
		this.store.dispatch(new ToggleFolder(node.id));
	}

	downloading(node) {
		if(this._downloading && this._lastNode.id == node.id) {
			return true;
		}
		else
			return false;
	}

	generatingLink(node) {
		if(this._generatingLink && this._lastNode.id == node.id) {
			return true;
		}
		else
			return false;
	}

	download(node: any) {
		this._lastNode = node;
		if (node.type != "folder") {
			this._downloading = true;
			this.facadeService.supportwebwsService.downloadAttachment(node.url).subscribe(x => {
				debugger;
				if (x.filename.includes("?")) {
					var result = x.filename.split("?");
					x.filename = decodeURIComponent(escape(atob(result[result.length - 2])));
				}
				this._downloading = false;
				FileSaver.saveAs(x.data, x.filename);
			},
				error => { this._downloading = false; this.facadeService.showMessage("Error", "", error.Message); });
			
		}
	}

	getLink(node) {
		this._lastNode = node;
		if (node.type != "folder") {
			this._generatingLink = true;
			this.facadeService.supportwebwsService.getFileAnonymousLink(node.url).subscribe(link => {
				debugger;
				this._generatingLink = false;
				window.open(link, "_blank");
			},
				error => { this._generatingLink = false; this.facadeService.showMessage("Error", "", error.Message); });
			
		}
	}

	showNode(node) {
		if (node.type === "folder") this.store.dispatch(new GetNode(node.id));
	}

	showActionSheet(node) {
		this.unselectNodes();
		// this.fileBrowserActionSheet.presentActionSheet(node, "tree");
	}

	unselectNodes() {
		this.store.dispatch(new UnselectNode());
	}

	openNode(node) {
		this.store.dispatch(new Open([node.id]));
	}
}