import { Component, OnInit, Input } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {

  @Input() roles: any;

  constructor(private router: Router, private facadeService: FacadeService) { }

  ngOnInit() {
    // this.facadeService.supportwebwsService.getRoles().subscribe(roles => {
    //   this.roles = roles;
    // });
  }

  // ionViewWillEnter() {
  //   debugger;
  // }

  click(user) {
    this.router.navigate(["role/", user.Id]);
  }

  newRole() {
    this.router.navigate(["role/", "newrole"]);
  }
}
