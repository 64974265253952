import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {

  roles: any;

  constructor(private facadeService: FacadeService) { }

  ngOnInit() {
    this.facadeService.menuIndexSelected = 5;
  }

  ionViewWillEnter() {
    this.facadeService.supportwebwsService.getRoles().subscribe(roles => {
      this.roles = roles;
    });
    debugger;
  }
}
