import { Component, OnInit } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent implements OnInit {

  role: any;
  id: string;
  isNewRole: boolean = false;
  isLoading: boolean;

  constructor(private route: ActivatedRoute, private facadeService: FacadeService, public loadingController: LoadingController) {
    this.role = {
      Attributes: {
        ebs_incidentvisibilitycode: {Value: 866980000}
      }
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      if(this.id != "newrole") {
        this.facadeService.supportwebwsService.getRole(this.id).subscribe(role => {
          debugger;
          this.role = role;
        });  
      }
      else {
        this.isNewRole = true;
      }
    });
  }

  selectVisibility($event) {
    this.role.Attributes.ebs_incidentvisibilitycode = { Value: +$event.detail.value };
  }

  save() {
    this.loadingPresent();
    if(this.isNewRole) {
      this.facadeService.supportwebwsService.createRole(this.role).subscribe(x => {
        if(x != this.guidEmpty()) {
          this.loadingDismiss();
          this.facadeService.navigateBack();
        }
      }, error => {
        this.loadingDismiss();
      });  
    }
    else {
      this.facadeService.supportwebwsService.updateRole(this.id, this.role).subscribe(x => {
        if(x != this.guidEmpty()) {
          this.loadingDismiss();
          this.facadeService.navigateBack();
        }
      }, error => {
        this.loadingDismiss();
      });  
    }
  }

  delete() {
    this.facadeService.supportwebwsService.deleteRole(this.id).subscribe(x => {
      if(x) {
        this.facadeService.navigateBack();
      }
    });  
  }

  public guidEmpty(): string {
		return '00000000-0000-0000-0000-000000000000';
	}

  async loadingPresent() {
    this.isLoading = true;
    return await this.loadingController.create({
      message: 'Ukládam požadavek...',
      spinner: 'crescent',
      cssClass: 'custom-class custom-loading'
    }).then(a => {
      a.present().then(() => {
        console.log('loading presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort laoding'));
        }
      });
    });
  }

  async loadingDismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('loading dismissed'));
  }

}
