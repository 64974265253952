import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthenticationService, public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    debugger;
    if (state.url == "/") {
      return new Observable(o => {
        this.auth.isAuthenticated().subscribe(a => {
          if(a == true) {
            o.next(true);
            o.complete();
            this.router.navigate(['incidentlist']);
          }
          else {
            o.next(false);
            o.complete();
            this.router.navigate(['login']);
          }
        })
      }) 
    }

    if (state.url == "/admin") {
      return new Observable(o => {
        this.auth.getPrivileges().subscribe(p => {
          if (p.Administration == true) {
            o.next(true);
            o.complete();
          }
          else {
            o.next(false);
            o.complete();
          }
        })
      });
    }

    // if (!this.auth.isAuthenticated()) {
    //     this.router.navigate(['login']);
    //     return false;
    // }

    return this.auth.isAuthenticated();
  }
}
