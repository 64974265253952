import { Attribute } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { attachView } from '@ionic/angular/providers/angular-delegate';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-licencerequest',
  templateUrl: './licencerequest.component.html',
  styleUrls: ['./licencerequest.component.scss'],
})
export class LicencerequestComponent implements OnInit {

  id: any;
  isLoading: boolean;

  canCreate: boolean = false;

  line: any = {
    Attributes: {}
  };
  licence: any = {
    Attributes: {},
    ForattedValues: {}
  };

  newquantity: number;
  licencechangefrom: any;

  pricePerOneCurrent: string;
  pricePerOneNew: string;
  totalPriceCurrent: string;
  totalPriceNew: string;

  pricePerOneCurrentValue: number;
  pricePerOneNewValue: number;
  totalPriceCurrentValue: number;
  totalPriceNewValue: number;
  userPrivileges: { CreateLicenceRequest: boolean; };
  searching: any = false;
  maxDate: string;

  constructor(private route: ActivatedRoute, private facadeService: FacadeService, public loadingController: LoadingController, private authService: AuthenticationService) { }

  ngOnInit() {
    debugger;
    let date = new Date();
    date.setFullYear(date.getFullYear()+5);
    this.maxDate = `${date.getFullYear()}-12-31`;

    this.userPrivileges = { CreateLicenceRequest: false };
    this.authService.getPrivileges().subscribe(p => {
      debugger;
      this.userPrivileges = p;
    });

    this.searching = true;

    this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number

      this.facadeService.supportwebwsService.getLicenceContractLine(this.id).subscribe(result => {
        this.line = result;

        this.newquantity = this.line.Attributes.ebs_quantity;

        this.facadeService.supportwebwsService.getLicence(this.line.Attributes.ebs_licenceid.Id).subscribe(result => {
          this.licence = result;
        }, error => {
          this.handleError(error);
        });

        this.facadeService.supportwebwsService.getLicenceRequestsForLine(this.line.Attributes.ebs_licencecontractlineid).subscribe(result => {
          debugger;
          this.line.Requests = result;

          this.canCreate = (result.filter(x => x.Attributes.statecode.Value == 0)).length == 0;
        }, error => {
          this.handleError(error);
        });

        this.facadeService.supportwebwsService.getLicenceContractLinesByParentLine(this.line.Attributes.ebs_licencecontractlineid).subscribe(result => {
          this.line.ChildLines = result;

          this.line.ChildLines.forEach(child => {
            debugger;
            this.facadeService.supportwebwsService.getSellingPricesToLicenceContractLine(child.Id).subscribe(result => {
              child.SellingPrices = result;
            });    
          });

        });

        this.facadeService.supportwebwsService.getSellingPricesToLicenceContractLine(this.line.Attributes.ebs_licencecontractlineid).subscribe(result => {
          debugger;
          this.line.SellingPrices = result;

          // if (this.line.SellingPrices != null && this.line.SellingPrices.length > 1) {
          var prices = this.line.SellingPrices.filter(x => x.Attributes.ebs_quantity <= this.line.Attributes.ebs_quantity);
          prices = prices.filter(x => x.Attributes.ebs_sellingpriceperquantity.Value === Math.min(...prices.map(f => f.Attributes.ebs_sellingpriceperquantity.Value)));
          this.pricePerOneCurrentValue = prices.length > 0 ? prices[0].Attributes.ebs_sellingpriceperquantity.Value : this.line.Attributes.ebs_sellingpriceperonepiece.Value;
          this.totalPriceCurrentValue = prices.length > 0 ? this.line.Attributes.ebs_quantity * prices[0].Attributes.ebs_sellingpriceperquantity.Value : this.line.Attributes.ebs_quantity * this.line.Attributes.ebs_sellingpriceperonepiece.Value;
          let currency = prices.length > 0 ? prices[0].FormattedValues.transactioncurrencyid : this.line.FormattedValues.transactioncurrencyid;

          // this.pricePerOneCurrent = this.formatPrice(this.pricePerOneCurrentValue, currency);
          this.pricePerOneCurrent = prices.length > 0 ? prices[0].FormattedValues.ebs_sellingpriceperquantity : this.line.FormattedValues.ebs_sellingpriceperonepiece;
          // this.totalPriceCurrent = this.formatPrice(this.totalPriceCurrentValue, currency);
          this.totalPriceCurrent = this.line.FormattedValues.ebs_totalprice
          // }

          this.pricePerOneCurrent = this.line.FormattedValues.ebs_sellingpriceperonepiecewithchilds ? this.line.FormattedValues.ebs_sellingpriceperonepiecewithchilds : this.pricePerOneCurrent;
          this.totalPriceCurrent = this.line.FormattedValues.ebs_totalpricewithchildlines ? this.line.FormattedValues.ebs_totalpricewithchildlines : this.totalPriceCurrent;

          this.searching = false;

        }, error => {
          this.handleError(error);
        });
      }, error => {
        this.handleError(error);
      });

    });
  }

  handleError(error) {
    this.searching = false;
  }

  calculatePrice() {
    // if (this.line.SellingPrices != null && this.line.SellingPrices.length > 1) {
    var prices = this.line.SellingPrices.filter(x => x.Attributes.ebs_quantity <= this.newquantity);
    prices = prices.filter(x => x.Attributes.ebs_sellingpriceperquantity.Value === Math.min(...prices.map(f => f.Attributes.ebs_sellingpriceperquantity.Value)));
    this.pricePerOneNewValue = prices.length > 0 ? prices[0].Attributes.ebs_sellingpriceperquantity.Value : (this.line.Attributes.ebs_sellingpriceperonepiece != undefined ? this.line.Attributes.ebs_sellingpriceperonepiece.Value : 0);
    // this.totalPriceNewValue = prices.length > 0 ? this.newquantity * prices[0].Attributes.ebs_sellingpriceperquantity.Value : this.newquantity * this.line.Attributes.ebs_sellingpriceperonepiece.Value;
    this.totalPriceNewValue = this.newquantity * this.pricePerOneNewValue;
    let currency = prices.length > 0 ? prices[0].FormattedValues.transactioncurrencyid : this.line.FormattedValues.transactioncurrencyid;

    let pricePerOneNewValueWithChildsVal = 0;
    let totalPriceNewVWithChildsalue = 0;

    if(this.line.ChildLines) {
      this.line.ChildLines.forEach(child => {
        var prices = child.SellingPrices.filter(x => x.Attributes.ebs_quantity <= this.newquantity);
        prices = prices.filter(x => x.Attributes.ebs_sellingpriceperquantity.Value === Math.min(...prices.map(f => f.Attributes.ebs_sellingpriceperquantity.Value)));

        pricePerOneNewValueWithChildsVal += prices.length > 0 ? prices[0].Attributes.ebs_sellingpriceperquantity.Value : (child.Attributes.ebs_sellingpriceperonepiece != undefined ? child.Attributes.ebs_sellingpriceperonepiece.Value : 0);
      });
      totalPriceNewVWithChildsalue += this.newquantity * pricePerOneNewValueWithChildsVal;
    }

    this.pricePerOneNew = this.formatPrice(this.pricePerOneNewValue + pricePerOneNewValueWithChildsVal, currency);
    this.totalPriceNew = this.formatPrice(this.totalPriceNewValue + totalPriceNewVWithChildsalue, currency);
    // }
  }

  formatPrice(price, currency) {
    debugger

    switch (currency) {
      case "CZK":
      case "česká koruna":
        // return `${price} Kč`;
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: 'CZK',

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(price);

      case "USD":
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(price);
      // return `$${price}`;

      case "EUR":
      case "Euro":
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'EUR',

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(price);
      // return `€${price}`;

      case "GBP":
      case "Libra šterlinků":
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'GBP',

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(price);
      // return `£${price}`;

    }
  }



  send() {

    if (this.newquantity == null) {
      this.facadeService.showMessage("Chyba", "", "Vyplňte nové množství");
      return;
    }

    if (!this.licencechangefrom) {
      this.facadeService.showMessage("Chyba", "", "Vyplňte datum pro změnu množství licencí");
      return;
    }

    this.loadingPresent();
    this.facadeService.supportwebwsService.createLicenceRequest(this.line, this.newquantity, this.licencechangefrom, this.pricePerOneNewValue, this.totalPriceNewValue).subscribe(result => {
      this.loadingDismiss();
      this.facadeService.navigateBack();
    }, error => {
      this.loadingDismiss();
      debugger;
      if (error.status == 401) {
        this.facadeService.showMessage("Chyba", "", "Nemáte oprávnění zakládat licenční požadavky");
        return;
      }

      this.facadeService.showMessage("Chyba", "", error.error.Message);
    });
  }


  async loadingPresent() {
    this.isLoading = true;
    return await this.loadingController.create({
      message: 'Zakládám požadavek...',
      spinner: 'crescent',
      cssClass: 'custom-class custom-loading'
    }).then(a => {
      a.present().then(() => {
        console.log('loading presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort laoding'));
        }
      });
    });
  }

  async loadingDismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('loading dismissed'));
  }

  openNewTab() {
    window.open(this.licence.Attributes.ebs_contractconditions, '_blank');
  }
}
