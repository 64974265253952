import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  passwordType: string = 'password';
  passwordIcon: string = 'eye';
  passwordType2: string = 'password';
  passwordIcon2: string = 'eye';

  pwd1: string;
  pwd2: string;

  config: any;

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  hideShowPassword2() {
    this.passwordType2 = this.passwordType2 === 'text' ? 'password' : 'text';
    this.passwordIcon2 = this.passwordIcon2 === 'eye-off' ? 'eye' : 'eye-off';
  }

  constructor(private facadeService: FacadeService, private configurationService: ConfigurationService) {

    this.config = configurationService.config;

  }

  ngOnInit() {
    this.facadeService.menuIndexSelected = 6;
  }

  switchLanguage(language: string) {
    debugger;
    this.facadeService.translate.use(this.configurationService.getLanguage(language));
    this.config.language = language;
    this.configurationService.save(this.config);
    //this.language = language;
  }

  resetPassword() {
    if (this.pwd1 != "" && this.pwd2 != "") {
      if (this.pwd1 == this.pwd2) {
        this.facadeService.supportwebwsService.resetPassword(this.pwd1).subscribe(() => {
          this.facadeService.showMessage("Info", "", "Heslo změněno");
        },
          error => {
            this.facadeService.showMessage("Error", "", error.error.Message);
          });
      }
      else {
        this.facadeService.showMessage("Info", "", "Hesla nejsou stejná");
      }
    }
    else {
      this.facadeService.showMessage("Info", "", "Heslo nesmí být prázdné");
    }
  }

}
