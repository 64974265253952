import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FacadeService } from 'src/app/services/facade.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {

  roles: any;
  rolebindings: any;
  id: string;
  selected: any = [];
  bindings: any = [];
  allroles: any = [];
  user: any;

  constructor(private route: ActivatedRoute, private facadeService: FacadeService, private changeRef: ChangeDetectorRef) { 
    this.rolebindings = [
      { FormattedValues : {} }
    ];
    
    this.roles = [
      { FormattedValues : {}, Attributes : {} }
    ];

    this.user = { FormattedValues : {}, Attributes : {} };
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      
debugger;
      this.facadeService.supportwebwsService.getUser(this.id).subscribe(u => {
        debugger;
        this.user = u;
      });

      this.facadeService.supportwebwsService.getRoleBindings(this.id).subscribe(rb =>{
        this.rolebindings = rb;
        this.rolebindings.forEach(role => {
          debugger;
          this.selected.push(role.Attributes.ebs_contactroleid.Id);
          this.bindings.push(role.Attributes.ebs_contactroleid.Id);
        });
        this.changeRef.detectChanges();
      });
      this.facadeService.supportwebwsService.getRoles().subscribe(r =>{
        this.roles = r;
        this.roles.forEach(role => {
          this.allroles.push(role.Id);
        })
        this.changeRef.detectChanges();
      });
    });
  }

  compare(o1, o2) {
    debugger;
    return o1 == o2
  }

  selectRoles($event) {
    // debugger;
    // var roleid = $event.detail.value;
debugger;
    // this.selected = $event.detail.value;
  }

  save() {
    debugger;
    var toAdd = [];
    var toRemove = [];

    this.allroles.forEach(role => {
      var b = this.bindings.find(x => x == role) != null ? true : false;
      var s = this.selected.find(x => x == role) != null ? true : false;

      // remove from crm bindings
      if(b == true && s == false) {
        var bindingid = this.rolebindings.find(x => x.Attributes.ebs_contactroleid.Id == role)
        toRemove.push(bindingid.Id);
      }

      // add to crm bindings
      if(b == false && s == true) {
        var r = this.roles.find(x => x.Id == role);
        toAdd.push({
          name: r.Attributes.ebs_name + " - " + this.user.Attributes.fullname,
          role: role,
          contact: this.id
        })
      }
    });

    const add$ = this.facadeService.supportwebwsService.createRoleBindings(toAdd);
    const remove$ = this.facadeService.supportwebwsService.deleteRoleBindings(toRemove);

    combineLatest(add$, remove$, (add, remove) => ({add, remove}))
      .subscribe(pair => {
           if(pair.add == true && pair.remove == true)
           {
             this.facadeService.navigateBack();
           }
       })
  }

}
